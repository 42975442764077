import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"
import { navigate } from "gatsby"
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import "./search.scss"

const SearchBox = () => {
  const state = useContext(GlobalStateContext)
  const [searchQuery, setSearchQuery] = useState("")
  const [coursesList, setcoursesList] = useState([])

  const dispatch = useContext(GlobalDispatchContext)

  const fetchAllCourses = useCallback(() => {
    const allCourses = []
    // eslint-disable-next-line array-callback-return

    if (state) {
      state.courses.map((course, index) => {
        const overview =
          course.overview?.length > 140
            ? course.overview.substring(0, 140) + "..."
            : course.overview
        const searchResultObj = {
          key: course.slug,
          id: index,
          title: course.title,
          overview: overview,
          tag: Array.isArray(course.category) ? course.category.join() : '',
          value: [
            course.title,
            <span>{overview}</span>,
            <div className="tags">
              {course.category &&
                course.category.map((tag, i) => (
                  <span key={i} className="autodesk_btn">
                    {tag}
                  </span>
                ))}
            </div>,
          ],
        }
        allCourses.push(searchResultObj)
      })
    }

    setcoursesList(allCourses)
  }, [state])

  useEffect(() => {
    state && fetchAllCourses()
    return () => {
      setcoursesList([])
    }
  }, [state, fetchAllCourses])

  const handleClickSearch = (obj) => {
    const searchQuery = obj.title
    if (searchQuery && searchQuery.length > 0) {
      dispatch({
        type: "SET_COURSE_SEARCH_QUERY",
        payload: searchQuery,
      })

      const searchResults = state?.courses.filter(
        (course) =>
          course.title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      )

      dispatch({
        type: "SET_COURSE_SEARCH_RESULTS",
        payload: searchResults,
      })

      const query = searchQuery.trim().replaceAll(" ", "-").toLowerCase()

      navigate(`/courses?searchquery=${query}`)
    }
  }

  const formatResult = (item) => {
    return (
      <div className="react-search-box-dropdown-list-item">
        <div>{item.title}</div>
        <span>{item.overview}</span>
        {item.value[2]}
      </div>
    )
  }

  return (
    <>
      <div className="customSearchboxContainer">
        <div className="searchIconCover">
          <StaticImage
            src="../../../assets/images/search.svg"
            alt=""
          />
        </div>
        <ReactSearchAutocomplete
          inputSearchString={searchQuery}
          items={coursesList}
          onSelect={handleClickSearch}
          placeholder="Search for Courses"
          fuseOptions={{ keys: ["title"] }}
          resultStringKeyName="title"
          formatResult={formatResult}
          showIcon={false}
          maxResults={5}
          showNoResults={false}
          styling={{
            border: 'none',
            boxShadow: 'none',
            searchIconMargin: -1,
            borderRadius: '0'
          }}
        />
        <button
          className="searchbtn"
          onClick={handleClickSearch}
        >
          Search
        </button>
      </div>
    </>
  )
}

export default SearchBox
